body {
  font-family: "Mulish", sans-serif;
  color: rgb(107, 100, 91);
}

.my-row {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  color: rgb(107, 100, 91);
}

.ofw {
  fill: url(#my-cool-gradient) #447799;
}

.episode h1,
h4 {
  opacity: 0.7;
}

.episode p {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #a1a1a13f;
  color: rgba(107, 100, 91, 0.685);
}

.episode img {
  height: 150px;
  float: right;
}
